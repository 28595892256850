import React from "react"

import { useSearch } from "../../../hooks/useSearch"

import { SearchFilterItem } from "./SearchFilterItem"
import { SearchFilterItemManual } from "./SearchFilterItemManual"

export const withSearchFilterDropdown = Component => ({
  name = `SearchFilterDropdown`,
  setMeasuring,
  measuring,
  locales,
  filter,
  active,
  setActive,
  value,
  setValues,
  setAggregations,
  options,
}) => {
  const { getAggs, getValues, getFilterReact } = useSearch()

  const config = {
    ...filter.config,
    defaultQuery: () => getAggs(filter, options),
    react: {
      and: [...getFilterReact("collection"), "PublishedSensor", "HiddenSensor", "CollectionSensor"],
    },
    render: ({ aggregations, setQuery, loading }) => {
      const props = {
        active,
        value,
        setValues,
        getValues,
        setQuery,
        aggregations,
        setAggregations,
        measuring,
        setMeasuring,
        locales,
        filter,
        setActive,
        loading,
        options,
      }

      return filter.settings.valuesShow === "manual" ? <SearchFilterItemManual {...props} /> : <SearchFilterItem {...props} />
    },
  }

  Component.displayName = name
  return <Component config={config} />
}
