import React from "react"
import { graphql } from "gatsby"

import { useCore } from "../hooks/useCore"
import { useQueries } from "../hooks/useQueries"
import { useLocation } from "../hooks/useLocation"
import { useShopify } from "../hooks/useShopify"

import { Collection as Template } from "../components/Collection/Collection"

export const query = graphql`
  query($handle: String!) {
    collection: sanityCollection(shopify: { shopifyHandle: { eq: $handle }, shopifyDeleted: {ne: true} }) {
      ...CollectionFragment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
    products: sanityProductDetailsPage {
      _rawMeasuringGuide(resolveReferences: { maxDepth: 10 })
    }
    template: sanityCollectionPage {
      hoverImageIndex
    }
  }
  fragment CollectionFragment on SanityCollection {
    id
    title
    descriptionHtml
    image
    top
    bottom
    shopify {
      id: shopifyId
      handle: shopifyHandle
    }
  }
`

export default ({ data, ...props }) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const {
    queries: { GET_COLLECTION },
  } = useQueries()
  const { countryCode } = useLocation()
  const { useQuery } = useShopify()
  const { collection } = data

  const { data: live, loading, error } =
    isBrowser && collection?.shopify?.handle?.length
      ? useQuery(GET_COLLECTION, {
        fetchPolicy: `cache-and-network`,
        variables: { handle: collection.shopify.handle },
      })
      : { data: false, loading: false, error: false }

  if (error) console.error(error)

  return data.collection ? <Template {...props} {...data} live={live} loading={loading} /> : null
}
