import React from "react"

import { PlaceholderProduct, PlaceholderContent, PlaceholderImage } from "../SearchStyles"

interface Props {
  hidden: string
  horizontalSpacing?: boolean
  ratio: string
  screenWidth: boolean
  verticalSpacing?: boolean
  width: string
}

export const SearchPlaceholder = ({ hidden, ratio, screenWidth, horizontalSpacing = true, verticalSpacing = false, width }: Props) => (
  <PlaceholderProduct
    hidden={hidden}
    screenWidth={screenWidth}
    horizontalSpacing={horizontalSpacing}
    verticalSpacing={verticalSpacing}
    width={width}
    className={`group`}
  >
    <PlaceholderContent>
      <PlaceholderImage ratio={ratio} screenWidth={screenWidth} />
    </PlaceholderContent>
  </PlaceholderProduct>
)
