import React from "react"

export const withSearchLoading = Component => ({ name = `SearchLoading`, pagination, search }) => {
  const buildResults = pagination => {
    const results = []

    for (var index = 0; index < pagination; index++) {
      results.push({ id: index })
    }

    return results
  }

  const results = buildResults(pagination)

  Component.displayName = name
  return <Component results={results} search={search} />
}
