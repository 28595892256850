import React from "react"

import { withSearchResults } from "./withSearchResults"
import { SearchItem } from "../Item/SearchItem"
import { StyledContainer, ResultsRow, ResultsProducts } from "../SearchStyles"

interface Props {
  adminProductNormaliser: any
  collectionOption: any
  collection: {
    title: string
    handle: string
  }
  results: Array<any>
}

export const SearchResults = withSearchResults(({ adminProductNormaliser, collectionOption, collection, results }: Props) => (
  <StyledContainer width={`lg`}>
    <ResultsRow verticalSpacing>
      {results.length > 4 ? (
        <>
          <ResultsProducts width={`1/2`}>
            <ResultsRow as={`div`} verticalSpacing>
              <>
                {results.slice(0, 2).map((product, index) => (
                  <SearchItem
                    key={product.id}
                    collection={collection}
                    index={index}
                    list={collection?.title || `Search`}
                    product={adminProductNormaliser(product)}
                    verticalSpacing
                    width={`1/2 md:1/2`}
                    hoverImageIndex={collectionOption?.hoverImageIndex}
                  />
                ))}

                {results.slice(3, 5).map((product, index) => (
                  <SearchItem
                    key={product.id}
                    collection={collection}
                    index={index + 3}
                    list={collection?.title || `Search`}
                    hidden={`sm`}
                    product={adminProductNormaliser(product)}
                    verticalSpacing
                    width={`1/2 md:1/2`}
                    hoverImageIndex={collectionOption?.hoverImageIndex}
                  />
                ))}
              </>
            </ResultsRow>
          </ResultsProducts>

          <ResultsProducts width={`1/2`}>
            <SearchItem
              key={results[2].id}
              collection={collection}
              index={2}
              list={collection?.title || `Search`}
              product={adminProductNormaliser(results[2])}
              horizontalSpacing={false}
              ratio={`0-6`}
              hoverImageIndex={collectionOption?.hoverImageIndex}
            />
          </ResultsProducts>

          <ResultsProducts>
            <ResultsRow as={`div`} verticalSpacing>
              {results.slice(3, 5).map((product, index) => (
                <SearchItem
                  key={product.id}
                  collection={collection}
                  index={index + 5}
                  list={collection?.title || `Search`}
                  hidden={`md`}
                  product={adminProductNormaliser(product)}
                  verticalSpacing
                  width={`1/2 md:1/2`}
                  hoverImageIndex={collectionOption?.hoverImageIndex}
                />
              ))}

              {results.slice(5).map((product, index) => (
                <SearchItem
                  key={product.id}
                  collection={collection}
                  index={index + 7}
                  list={collection?.title || `Search`}
                  product={adminProductNormaliser(product)}
                  verticalSpacing
                  width={`1/2 md:1/4`}
                  hoverImageIndex={collectionOption?.hoverImageIndex}
                />
              ))}
            </ResultsRow>
          </ResultsProducts>
        </>
      ) : (
        results.map((product, index) => (
          <SearchItem
            key={product.id}
            collection={collection}
            index={index}
            list={collection?.title || `Search`}
            product={adminProductNormaliser(product)}
            verticalSpacing
            width={`1/2 md:1/4`}
            hoverImageIndex={collectionOption?.hoverImageIndex}
          />
        ))
      )}
    </ResultsRow>
  </StyledContainer>
))
