import React from "react"

import { useShopify } from "../../../hooks/useShopify"

export const withSearchResults = Component => ({ name = `SearchResults`, collectionOption, collection, results }) => {
  const { adminProductNormaliser } = useShopify()

  Component.displayName = name
  return <Component adminProductNormaliser={adminProductNormaliser} collectionOption={collectionOption} collection={collection} results={results} />
}
