import React from "react"

import { Container } from "../../Styled/Container"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { Panel, Row, Values, Value, Controls, PreviousIcon, StyledDropdownLabel, StyledTextButton } from "./SearchFilterStyles"

export const SearchFilterPanel = ({ active, buckets, filter, getValues, locales, setActive, setValues, value }) => (
  <Panel active={active}>
    <Container>
      <Controls>
        <StyledTextButton onClick={() => setActive(null)}>
          <PreviousIcon />
          <span>{locales.back}</span>
        </StyledTextButton>
      </Controls>

      <StyledDropdownLabel>{filter.title}</StyledDropdownLabel>

      <Row>
        <Values>
          {buckets
            ?.filter(({ key }) => !key?.toLowerCase()?.includes(`default title`))
            .map(
              bucket =>
                filter.view === "list" && (
                  <Value key={bucket.key}>
                    <Checkbox
                      key={bucket.key}
                      capitalize
                      centered
                      checked={value.includes(bucket.key)}
                      externalState
                      large
                      name={bucket?.key?.toString()}
                      onChange={({ name }) => {
                        setValues(prev => getValues(prev, name, filter))
                      }}
                    >
                      {bucket.key}
                    </Checkbox>
                  </Value>
                )
            )}
        </Values>
      </Row>
    </Container>
  </Panel>
)
