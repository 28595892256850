import React, { useEffect, useState } from "react"

import { useSearch } from "../../../hooks/useSearch"
import { useShopify } from "../../../hooks/useShopify"

export const withSearchFilterItemManual = Component => ({
  name = `SearchFilterItemManual`,
  active,
  aggregations,
  filter,
  getValues,
  locales,
  measuring,
  setActive,
  setAggregations,
  setMeasuring,
  setQuery,
  setValues,
  value,
}) => {
  const [innerValue, setInnerValue] = useState([])
  const { getQuery } = useSearch()
  const { formatMoney } = useShopify()

  useEffect(() => {
    if (aggregations) {
      const data =
        filter.field === "variants.option1.keyword"
          ? aggregations?.[filter.field]?.variants?.only_size.buckets?.length || aggregations?.[filter.field]?.variants?.only_size.doc_count
          : aggregations?.[filter.field]?.buckets?.length || aggregations?.[filter.field]?.doc_count
      setAggregations(prev => ({
        ...prev,
        [filter.title]: data || 0,
      }))
    }
  }, [aggregations])

  useEffect(() => {
    if (value && (value.filter(v => !innerValue.filter(iv => iv === v).length).length || value.length !== innerValue.length)) {
      setQuery(getQuery(value, filter))
      setInnerValue(value)
    }
  }, [value])

  const buckets = filter.settings.valuesManual.map(value => {
    let key, label

    if (filter.id === "price") {
      if (value.includes("Under ")) {
        key = `Under ${formatMoney(value.replace("Under ", ""), false)}`
        label = `Under ${formatMoney(value.replace("Under ", ""))}`
      } else if (value.includes(" +")) {
        key = `${formatMoney(value.replace(" +", ""), false)} +`
        label = `${formatMoney(value.replace(" +", ""))} +`
      } else {
        const [min, max] = value.split(" - ")
        key = `${formatMoney(min, false)} - ${formatMoney(max, false)}`
        label = `${formatMoney(min)} - ${formatMoney(max)}`
      }
    }

    return {
      key,
      value,
      label,
    }
  })

  Component.displayName = name
  return (
    <Component
      active={active}
      buckets={buckets}
      filter={filter}
      getValues={getValues}
      locales={locales}
      measuring={measuring}
      setActive={setActive}
      setMeasuring={setMeasuring}
      setValues={setValues}
      value={value}
    />
  )
}
