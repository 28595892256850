import React from "react"

import { SearchComponents } from "../../../hooks/useSearch"

export const SearchSensorCollection = ({ collection }) => (
  <SearchComponents.ReactiveComponent
    componentId="CollectionSensor"
    customQuery={() => ({
      query: {
        bool: {
          should: [
            {
              nested: {
                path: "collections",
                query: {
                  term: {
                    "collections.handle.keyword": collection?.shopify?.handle,
                  },
                },
              },
            },
            {
              nested: {
                path: "curations",
                query: {
                  term: {
                    "curations.collectionHandle.keyword": collection?.shopify?.handle,
                  },
                },
              },
            },
          ],
        },
      },
    })}
  />
)
