import React from "react"

import { withSearchLoading } from "./withSearchLoading"
import { SearchPlaceholder } from "./SearchPlaceholder"
import { StyledContainer, LoadingRow, LoadingProducts } from "../SearchStyles"

interface Props {
  results: Array<any>
  search: boolean
}

export const SearchLoading = withSearchLoading(({ results, search }: Props) => (
  <StyledContainer search={search} width={`lg`}>
    <LoadingRow verticalSpacing>
      <LoadingProducts width={`1/2`}>
        <LoadingRow as={`div`} verticalSpacing>
          <>
            {results.slice(0, 2).map(item => (
              <SearchPlaceholder key={item.id} verticalSpacing width={`1/2 md:1/2`} />
            ))}

            {results.slice(3, 5).map(item => (
              <SearchPlaceholder key={item.id} hidden={`sm`} verticalSpacing width={`1/2 md:1/2`} />
            ))}
          </>
        </LoadingRow>
      </LoadingProducts>

      <LoadingProducts width={`1/2`}>
        <SearchPlaceholder key={results[2].id} horizontalSpacing={false} ratio={`0-6`} />
      </LoadingProducts>

      <LoadingProducts>
        <LoadingRow as={`div`} verticalSpacing>
          {results.slice(3, 5).map(item => (
            <SearchPlaceholder key={item.id} hidden={`md`} verticalSpacing width={`1/2 md:1/2`} />
          ))}

          {results.slice(5).map(item => (
            <SearchPlaceholder key={item.id} verticalSpacing width={`1/2 md:1/4`} />
          ))}
        </LoadingRow>
      </LoadingProducts>
    </LoadingRow>
  </StyledContainer>
))
