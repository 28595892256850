import React from "react"

import { DesktopFilter, MobileFilter, StyledLabel, Sup, ArrowRightIcon } from "./SearchFilterStyles"

export const SearchFilterList = ({ active, setActive, filter, value }) => (
  <>
    {filter.view === "list" && (
      <>
        <DesktopFilter active={active === filter.title} onMouseEnter={() => setActive(filter.title)} onMouseLeave={() => setActive(null)}>
          <StyledLabel>
            {filter.title}
            {value && value.length ? <Sup>{value.length}</Sup> : null}
          </StyledLabel>
        </DesktopFilter>
        <MobileFilter active={active ? `true` : undefined} onClick={() => setActive(filter.title)}>
          <StyledLabel>
            {filter.title}
            {value && value.length ? <Sup>{value.length}</Sup> : null}
          </StyledLabel>
          <ArrowRightIcon active={active === filter.title} />
        </MobileFilter>
      </>
    )}
  </>
)
