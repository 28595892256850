import React, { ReactElement } from "react"

import { SearchComponents } from "../../hooks/useSearch"

import { withCollection } from "./withCollection"
import { Container } from "../Styled/Container"
import { ProductContentSizeGuide } from "../Product/Content/ProductContentSizeGuide"
import { SearchEmpty } from "../Search/Results/SearchEmpty"
import { SearchLoading } from "../Search/Loading/SearchLoading"
import { SearchPagination } from "../Search/Pagination/SearchPagination"
import { SearchResults } from "../Search/Results/SearchResults"
import { SearchSensorCollection } from "../Search/Sensors/SearchSensorCollection"
import { SearchFilter } from "../Search/Filter/SearchFilter"
import { FilterContext } from "../../providers/filters"
import {
  CollectionHeaderWrapper,
  CollectionMobileHeader,
  CollectionMobileHeaderColumn,
  CollectionH2,
  CollectionTextButton,
  CollectionDescription,
} from "./CollectionStyles"

interface Props {
  collection: any
  filters: any
  guide: any
  handleResultChange: Function
  locales: any
  measuring: any
  resultsConfig: any
  resultCount: any
  setFilters: Function
  setMeasuring: Function
  products: any
  template: any
  url: string
}

const OptionalCollectionDescription = ({ label, as }: { label: string; as: string }): ReactElement =>
  label ? <CollectionDescription as={as}>{`${label}`}</CollectionDescription> : null

export const Collection = withCollection(
  ({ collection, filters, guide, handleResultChange, locales, measuring, resultsConfig, resultCount, setFilters, setMeasuring, template }: Props) => (
    <>
      <CollectionMobileHeader>
        <CollectionMobileHeaderColumn align={`right`}>
          <CollectionTextButton onClick={() => setFilters(true)}>{locales.filterLabel}</CollectionTextButton>
        </CollectionMobileHeaderColumn>
      </CollectionMobileHeader>

      <CollectionHeaderWrapper>
        <Container>
          <CollectionH2 as={`h1`}>{`${collection.title}`}</CollectionH2>
          <OptionalCollectionDescription as={`p`} label={collection.top} />
        </Container>
      </CollectionHeaderWrapper>

      <SearchSensorCollection collection={collection} />
      <FilterContext.Provider value={resultCount}>
        <SearchFilter collection={collection} filtersActive={filters} setFilters={setFilters} setFiltersActive={setFilters} />
      </FilterContext.Provider>

      <SearchComponents.ReactiveList
        {...resultsConfig}
        renderNoResults={() => <SearchEmpty locales={locales} />}
        render={({ data, loading }) =>
          loading ? (
            <SearchLoading pagination={resultsConfig?.size} />
          ) : (
            <SearchResults results={data} collection={collection} collectionOption={template} />
          )
        }
        renderPagination={pagination => <SearchPagination {...pagination} />}
        onData={handleResultChange}
      />

      <ProductContentSizeGuide active={measuring} content={guide} setActive={setMeasuring} />
      <OptionalCollectionDescription as={`p`} label={collection.bottom} />
    </>
  )
)
