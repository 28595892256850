import React, { useCallback, useState } from "react"

import { Filter, StyledLabel } from "./SearchFilterStyles"

export const withSearchFilterPalette = Component => ({ name = `SearchFilterPalette`, filter, locales }) => {
  const [value, setValue] = useState(null)

  const handleChange = useCallback(val => {
    setValue(val)
  }, [])

  const config = {
    componentId: filter.id,
    react: {
      and: ["PublishedSensor", "HiddenSensor", "CollectionSensor"],
    },
    defaultQuery: () => ({
      aggs: {
        vendors: {
          terms: {
            field: filter.field,
          },
        },
      },
    }),
    render: ({ aggregations, setQuery }) => {
      const values = aggregations?.[filter.field]?.buckets?.filter(({ key }) => filter.settings.valuesManual.includes(key))

      return (
        <>
          <Filter
            active={!value}
            onClick={() => {
              setQuery({})
              handleChange(null)
            }}
          >
            <StyledLabel palette>{locales.all}</StyledLabel>
          </Filter>

          {values?.length > 1
            ? values.map(({ key }) => (
                <Filter
                  key={key?.toString()}
                  active={value === key}
                  onClick={() => {
                    setQuery({
                      query: {
                        term: { [filter.field]: key },
                      },
                    })
                    handleChange(key)
                  }}
                >
                  <StyledLabel palette>{key}</StyledLabel>
                </Filter>
              ))
            : null}
        </>
      )
    },
  }

  Component.displayName = name
  return <Component config={config} />
}
