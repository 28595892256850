import React from "react"

import { Toggle } from "./SearchFilterStyles"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { SearchComponents } from "../../../hooks/useSearch"

export const SearchFilterGrid = ({ filters, active, setActive }) => (
  <>
    {filters
      .filter(({ view }) => view === "box")
      .map(filter => (
        <SearchComponents.ReactiveComponent
          {...filter.config}
          key={filter.title?.toString()}
          defaultQuery={() => {}}
          render={() =>
            filter.settings.valuesManual?.length > 0 && (
              <>
                {filter.settings.valuesManual?.map(key => (
                  <Toggle key={key.toString()}>
                    <Checkbox
                      centered
                      checked={active}
                      externalState
                      onChange={() => {
                        setActive(prev => !prev)
                      }}
                      rounded
                    >
                      {key?.replace(/-/g, ` `)}
                    </Checkbox>
                  </Toggle>
                ))}
              </>
            )
          }
        />
      ))}
  </>
)
