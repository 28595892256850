import React, { useEffect, useMemo, useState } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCore } from "../../../hooks/useCore"
import { useLanguage } from "../../../hooks/useLanguage"
import { useSearch } from "../../../hooks/useSearch"

export const withSearchFilter = Component => ({
  name = `SearchFilter`,
  collection,
  filtersActive,
  setFiltersActive,
  setMeasuring,
  type = "collection",
}) => {
  const [active, setActive] = useState(null)
  const [values, setValues] = useState({})
  const [readyToShip, setReadyToShip] = useState(false)
  const [aggregations, setAggregations] = useState({})
  const {
    config: {
      settings: { keys },
    },
  } = useApp()
  const {
    helpers: { storage },
  } = useCore()
  const { buildSortQuery, getSortOptions, getFiltersConfig, getDefaultSort } = useSearch()
  const filters = getFiltersConfig(type)
  const defaultSort = getDefaultSort(type)
  const sorting = getSortOptions(type)
  const defaultSorting = sorting.find(({ label }) => label === defaultSort) || sorting[0]
  const [sortBy, setSortBy] = useState(defaultSorting)

  const locales = {
    ...useLanguage(`accessibility`),
    ...useLanguage(`products`),
    ...useLanguage(`collection`),
  }

  const sortQuery = useMemo(() => {
    const sortQuery = buildSortQuery("collection", collection?.shopify?.handle, sortBy)

    return sortQuery
  }, [collection, sortBy])

  const handleCloseFilter = () => {
    setFiltersActive(null)
    setActive(null)
  }

  useEffect(() => {
    const filterValues = storage.get(keys.searchFilters)
    if (filterValues) {
      setValues(filterValues)
    }
  }, [])

  useEffect(() => {
    if (values) {
      storage.set(keys.searchFilters, values)
    }
  }, [values])

  Component.displayName = name
  return (
    <Component
      active={active}
      aggregations={aggregations}
      defaultSort={defaultSort}
      filters={filters}
      filtersActive={filtersActive}
      locales={locales}
      handleCloseFilter={handleCloseFilter}
      readyToShip={readyToShip}
      setActive={setActive}
      setAggregations={setAggregations}
      setMeasuring={setMeasuring}
      setReadyToShip={setReadyToShip}
      setSortBy={setSortBy}
      setValues={setValues}
      sorting={sorting}
      sortBy={sortBy}
      sortQuery={sortQuery}
      values={values}
    />
  )
}
