import React, { useEffect, useState } from "react"

import { useSearch } from "../../../hooks/useSearch"

export const withSearchFilterItem = Component => ({
  name = `SearchFilterItem`,
  active,
  aggregations,
  filter,
  getValues,
  locales,
  measuring,
  options,
  setActive,
  setAggregations,
  setMeasuring,
  setQuery,
  setValues,
  value,
}) => {
  const [innerValue, setInnerValue] = useState([])
  const { getQuery } = useSearch()

  const sortFilters = ({ key: keyA }, { key: keyB }) => {
    const stringOrder = ["Small", "Medium", "Large", "Extra Large"]

    if (!isNaN(keyA) && !isNaN(keyB)) return Number(keyA) - Number(keyB)
    if (isNaN(keyA) && !isNaN(keyB)) return 1
    if (!isNaN(keyA) && isNaN(keyB)) return -1

    const indexA = stringOrder.indexOf(keyA)
    const indexB = stringOrder.indexOf(keyB)

    if (indexA < 0 && indexB < 0) {
      if (keyA > keyB) return 1
      if (keyA < keyB) return -1
      return 0
    }
    if (indexA < 0) return 1
    if (indexB < 0) return -1

    return indexA - indexB
  }

  const reduceFilters = (items = []) => {
    const keywords = ["small", "medium", "large", "extra large"]
    return items?.filter(({ key }) => (key && !isNaN(key) && key !== "") || keywords.includes(key?.toLowerCase()))
  }

  useEffect(() => {
    const data =
      filter.field === "variants.option1.keyword"
        ? aggregations?.[filter.field]?.variants?.only_size.buckets?.length || aggregations?.[filter.field]?.variants?.only_size.doc_count
        : aggregations?.[filter.field]?.buckets?.length || aggregations?.[filter.field]?.doc_count
    if (data) {
      setAggregations(prev => ({
        ...prev,
        [filter.title]: data || 0,
      }))
    }
  }, [aggregations])

  useEffect(() => {
    if (value && (value.filter(v => !innerValue.filter(iv => iv === v).length).length || value.length !== innerValue.length)) {
      setQuery(getQuery(value, filter, options))
      setInnerValue(value)
    }
  }, [value])

  useEffect(() => {
    if (filter.field === "variants.option1.keyword") {
      setQuery(getQuery(value, filter, options))
      setInnerValue(value)
    }
  }, [options.readyToShip])

  let buckets = aggregations?.[filter.field]?.buckets || aggregations?.[filter.field]?.variants?.only_size?.buckets
  buckets = filter.field === "variants.option1.keyword" ? reduceFilters(buckets) : buckets

  const sortedBuckets = buckets?.sort(sortFilters)

  if (filter.field === "variants.option1.keyword" && options?.readyToShip) {
    buckets = buckets.reduce((result, item) => {
      if (item.stock?.buckets?.length > 0) {
        result.push(item)
      }
      return result
    }, [])
  }

  Component.displayName = name
  return (
    <Component
      active={active}
      buckets={sortedBuckets}
      filter={filter}
      getValues={getValues}
      locales={locales}
      measuring={measuring}
      setActive={setActive}
      setMeasuring={setMeasuring}
      setValues={setValues}
      value={value}
    />
  )
}
