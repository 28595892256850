import React from "react"

import { Small } from "../../Styled/Text"
import { FilterContext } from "../../../providers/filters"

export const SearchFilterCount = () => (
  <>
    <FilterContext.Consumer>
      {resultCount => (resultCount ? <Small>{`${resultCount.count} Results`}</Small> : <Small dangerouslySetInnerHTML={{ __html: `&nbsp;` }} />)}
    </FilterContext.Consumer>
  </>
)
