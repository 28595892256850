import React, { useEffect, useState } from "react"

import { useLanguage } from "../../hooks/useLanguage"
import { useSearch } from "../../hooks/useSearch"
import { useScrollHidden } from "../../hooks/useScroll"
import { useSanity } from "../../hooks/useSanity"

export const withCollection = Component => ({ name = `Collection`, collection, content, products, template }) => {
  const [filters, setFilters] = useState(false)
  const [measuring, setMeasuring] = useState(false)
  const [resultCount, setResultCount] = useState(null)
  const { getResultsConfig } = useSearch()
  const { textNormaliser } = useSanity()

  const resultsConfig = getResultsConfig()
  const locales = {
    ...useLanguage(`accessibility`),
    ...useLanguage(`collection`),
  }
  const guide = {
    ...products,
    measuring: products?._rawMeasuringGuide?.measuring ? textNormaliser(products._rawMeasuringGuide.measuring) : null,
    image: products?._rawMeasuringGuide?.image,
    video: products?._rawMeasuringGuide?.video?.link
      ? {
          link: products._rawMeasuringGuide.video.link,
        }
      : null,
  }

  const handleResultChange = ({ resultStats }) => {
    setResultCount({
      count: resultStats.numberOfResults,
      time: resultStats.time,
    })
  }

  useEffect(() => {
    useScrollHidden(filters)
  }, [filters])

  Component.displayName = name
  return (
    <Component
      collection={collection}
      content={content}
      filters={filters}
      guide={guide}
      handleResultChange={handleResultChange}
      locales={locales}
      measuring={measuring}
      resultCount={resultCount}
      resultsConfig={resultsConfig}
      setFilters={setFilters}
      setMeasuring={setMeasuring}
      template={template}
    />
  )
}
