import React from "react"

import { withSearchFilter } from "./withSearchFilter"
import { SearchFilterDropdown } from "./SearchFilterDropdown"
import { SearchFilterGrid } from "./SearchFilterGrid"
import { SearchFilterPalette } from "./SearchFilterPalette"
import { SearchFilterList } from "./SearchFilterList"

import { P } from "../../Styled/Text"
import {
  Wrapper,
  FilterPanel,
  StyledContainer,
  FiltersWrapper,
  Items,
  MobileHeader,
  MobileHeaderColumn,
  MobileFooter,
  CloseIcon,
  CloseButton,
  ResetButton,
  Overlay,
} from "./SearchFilterStyles"
import { PrimaryButton } from "../../Styled/Button"
import { SearchSort } from "../Sort/SearchSort"
import { SearchSortToggle } from "../Sort/SearchSortToggle"

export const SearchFilter = withSearchFilter(
  ({
    active,
    aggregations,
    defaultSort,
    filters,
    filtersActive,
    locales,
    handleCloseFilter,
    readyToShip,
    setActive,
    setAggregations,
    setMeasuring,
    setReadyToShip,
    setSortBy,
    setValues,
    sorting,
    sortBy,
    sortQuery,
    values,
  }) => (
    <Wrapper active={filtersActive}>
      <FilterPanel active={filtersActive}>
        <StyledContainer>
          <MobileHeader>
            <MobileHeaderColumn>
              <P>{locales.filterLabel}</P>
            </MobileHeaderColumn>

            <MobileHeaderColumn align={`right`}>
              <CloseButton onClick={handleCloseFilter}>
                <CloseIcon large />
              </CloseButton>
            </MobileHeaderColumn>
          </MobileHeader>

          <FiltersWrapper>
            <Items align={`start`}>
              <SearchFilterPalette locales={locales} filter={filters.find(({ view }) => view === "swatch")} />
            </Items>

            <Items>
              <>
                <SearchSortToggle active={active} setActive={setActive} />
                {filters
                  ?.filter(filter => aggregations[filter.title])
                  .map(filter => (
                    <SearchFilterList
                      key={filter.title?.toString()}
                      active={active}
                      value={values[filter.title] || []}
                      filter={filter}
                      setActive={setActive}
                    />
                  ))}
              </>
            </Items>

            <Items align={`end`}>
              <SearchFilterGrid filters={filters} values={values} active={readyToShip} setActive={setReadyToShip} />
            </Items>
          </FiltersWrapper>

          <MobileFooter>
            <ResetButton
              hidden={false}
              onClick={() => {
                setValues({})
              }}
            >
              {locales.filterReset} <CloseIcon large />
            </ResetButton>

            <PrimaryButton full={`true`} onClick={handleCloseFilter}>
              {!filtersActive ? `${locales.show} totalResults+ ${locales.filterItems}` : locales.close}
            </PrimaryButton>
          </MobileFooter>
        </StyledContainer>
      </FilterPanel>
      <Overlay active={active} />
      <SearchSort
        active={active}
        setActive={setActive}
        sorting={sorting}
        sortBy={sortBy}
        setSortBy={setSortBy}
        defaultSort={defaultSort}
        sortQuery={sortQuery}
      />
      {filters.reverse().map(filter => (
        <SearchFilterDropdown
          key={filter.title?.toString()}
          type={`list`}
          locales={locales}
          setMeasuring={setMeasuring}
          active={filter.title === active}
          value={values[filter.title] || []}
          setValues={setValues}
          setActive={setActive}
          filter={filter}
          setAggregations={setAggregations}
          options={{ readyToShip }}
        />
      ))}
    </Wrapper>
  )
)
