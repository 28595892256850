import React from "react"

import { withSearchSort } from "./withSearchSort"
import { Container } from "../../Styled/Container"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import {
  Dropdown,
  Content,
  Row,
  Values,
  Value,
  Panel,
  Controls,
  StyledTextButton,
  PreviousIcon,
  StyledDropdownLabel,
} from "../Filter/SearchFilterStyles"
import { SearchComponents } from "../../../hooks/useSearch"

export const SearchSort = withSearchSort(({ active, locales, setActive, sorting, sortBy, setSortBy, sortQuery }) => (
  <>
    <Dropdown active={active === locales.filterSortBy} onMouseEnter={() => setActive(locales.filterSortBy)} onMouseLeave={() => setActive(null)}>
      <Content>
        <Container width={`lg`}>
          <Row>
            <Values>
              <SearchComponents.ReactiveComponent
                componentId="SortingSensor"
                customQuery={() => ({
                  sort: sortQuery,
                })}
                render={() =>
                  sorting.map(option => (
                    <Value key={option.label} sorting>
                      <Checkbox
                        centered
                        checked={option.label === sortBy.label}
                        externalState
                        large
                        name={option.label.toString()}
                        onChange={() => {
                          setSortBy(option)
                        }}
                      >
                        {option.label}
                      </Checkbox>
                    </Value>
                  ))
                }
              />
            </Values>
          </Row>
        </Container>
      </Content>
    </Dropdown>

    <Panel active={active === locales.filterSortBy}>
      <Container>
        <Controls>
          <StyledTextButton onClick={() => setActive(null)}>
            <PreviousIcon />
            <span>{locales.back}</span>
          </StyledTextButton>
        </Controls>

        <StyledDropdownLabel>{locales.filterSortBy}</StyledDropdownLabel>

        <Row>
          <Values>
            {sorting.map(option => (
              <Value key={option.label}>
                <Checkbox
                  centered
                  checked={option.label === sortBy.label}
                  externalState
                  large
                  name={option.label.toString()}
                  onChange={() => setSortBy(option)}
                >
                  {option.label}
                </Checkbox>
              </Value>
            ))}
          </Values>
        </Row>
      </Container>
    </Panel>
  </>
))
