import React from "react"

import { withSearchFilterItemManual } from "./withSearchFilterItemManual"
import { Container } from "../../Styled/Container"
import { Small } from "../../Styled/Text"
import { Checkbox } from "../../Styled/Checkbox/Checkbox"
import { SearchFilterPanel } from "./SearchFilterPanel"
import { SearchFilterCount } from "./SearchFilterCount"
import { CloseIcon, Dropdown, Content, Row, Value, Controls, Values, Info, StyledLink, StyledTextButton } from "./SearchFilterStyles"

export const SearchFilterItemManual = withSearchFilterItemManual(
  ({ active, buckets, filter, getValues, locales, measuring, setActive, setMeasuring, setValues, value }) => (
    <>
      <Dropdown active={active} onMouseEnter={() => setActive(filter.title)} onMouseLeave={() => setActive(null)}>
        <Content>
          <Container width={`lg`}>
            <Row>
              <Values>
                {buckets.map(
                  bucket =>
                    filter.view === "list" && (
                      <Value key={bucket.key}>
                        <Checkbox
                          key={bucket.key}
                          capitalize
                          centered
                          checked={value.includes(bucket.key)}
                          externalState
                          large
                          name={bucket?.key?.toString()}
                          onChange={({ name }) => {
                            setValues(prev => getValues(prev, name, filter))
                          }}
                        >
                          {bucket.label}
                        </Checkbox>
                      </Value>
                    )
                )}
              </Values>
            </Row>

            <Row>
              <Info>
                <SearchFilterCount />

                {measuring && filter.title === "Size" ? (
                  <Small>
                    <StyledLink as={`button`} onClick={() => setMeasuring(true)}>
                      {locales.filterMeasuring}
                    </StyledLink>
                  </Small>
                ) : null}
              </Info>

              <Controls>
                <StyledTextButton onClick={() => setValues([])}>
                  {locales.filterReset} <CloseIcon />
                </StyledTextButton>
              </Controls>
            </Row>
          </Container>
        </Content>
      </Dropdown>

      <SearchFilterPanel
        active={active}
        setActive={setActive}
        locales={locales}
        filter={filter}
        buckets={buckets}
        getValues={getValues}
        setValues={setValues}
        value={value}
      />
    </>
  )
)
